import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"

import Layout from "../components/layout"
import Content, { HTMLContent } from "../components/Content"

export const AboutTemplate = ({ content, contentComponent, helmet }) => {
  const AboutContent = contentComponent || Content

  return (
    <section className="section">
      {helmet || ""}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <AboutContent className="markdown" content={content} />
          </div>
        </div>
      </div>
    </section>
  )
}

const AboutPage = ({ data }) => {
  const { markdownRemark: about } = data

  return (
    <>
      <Layout>
        <AboutTemplate
          content={about.html}
          contentComponent={HTMLContent}
          helmet={
            <Helmet titleTemplate="%s">
              <title>About</title>
            </Helmet>
          }
        />
      </Layout>
    </>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query About {
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      id
      html
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 800, quality: 70) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`
